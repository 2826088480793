import { Page } from '../../components/Page';
import { layout } from '../../styles/layout';
import { NotFoundPage } from '../NotFoundPage';
import { LoadingPage } from '../LoadingPage';
import { Stream } from '../../lib/graphql.document';
import { Loader } from '../../components/Loader';
import { PostBlocks } from '../../components/PostBlocks';
import { useMediaBlocks } from '../../react-hooks/useMediaPosts';
import { useFetchWhenInView } from '../../react-hooks/useFetchWhenInView';
import { AdvertisementId } from '../../components/Advertisement';
import { tw } from '../../styles/tw';
import { ROUTES } from '../../lib/ROUTES';

const MainPage = (): JSX.Element => {
  const { isDataReceived, onFetchMore, mediaBlocks, hasNextPage } = useMediaBlocks({ stream: Stream.Main });
  const { intersectionObserverRef } = useFetchWhenInView(onFetchMore);

  if (mediaBlocks) {
    return (
      <Page
        className={tw(layout, 'mb-5')}
        documentTitle={PUBLIC_CONFIG.APP_NAME}
        desktopAdvertisementId={AdvertisementId.DesktopMainTopBillboard}
        mobileAdvertisementId={AdvertisementId.MobileMainTopBillboard}
        overflowY="scroll"
        includeRssFeedLink
        canonicalUrl={ROUTES.ROOT.buildPath({})}
      >
        <PostBlocks blocks={mediaBlocks} />
        {hasNextPage && <Loader className="mx-auto my-5" ref={intersectionObserverRef} />}
      </Page>
    );
  }

  if (isDataReceived) {
    return <NotFoundPage />;
  }

  return <LoadingPage />;
};

export { MainPage };
export default MainPage;
